type Props = {
  type?: "primary" | "secondary";
  Icon?: JSX.Element | null;
  label?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
};

export default function Button({
  type = "primary",
  Icon = null,
  label = "",
  onClick,
  isLoading = false,
  disabled = false,
  className = "",
}: Props) {
  return (
    <button onClick={onClick} disabled={isLoading || disabled} className={`btn btn-${type} ${className}`}>
      {isLoading ? (
        <span className="loading loading-spinner" />
      ) : (
        <>
          {Icon}
          {label}
        </>
      )}
    </button>
  );
}
